import React from "react";
import "./index.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import { useParams } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../common/breadcumb";
import { TabTitle } from "../../common/dynamicTitle";
function ContactUs() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [setting, setSetting] = React.useState();

  const APIURL = `${process.env.REACT_APP_URL}site_settings`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setSetting(response.data.site_settings);

        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (loading) return "";
  if (error) return "";
  console.log(setting);
  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="wrapper">
          <div className="InsideFlexBox">
            <section>
              <div className="container">
                <div className="row">
                  <div className="contact-info">
                    <div className="contact-info-item">
                      <div className="contact-info-icon">
                        <LocationOnIcon />
                      </div>
                      <div className="contact-info-content">
                        <h4>Address</h4>
                        <p>{setting.address}</p>
                      </div>
                    </div>
                    <div className="contact-info-item">
                      <div className="contact-info-icon">
                        <PhoneIphoneIcon />
                      </div>
                      <div className="contact-info-content">
                        <h4>Phone</h4>
                        <p>
                          <Link to={`tel:${setting.mobile}`}>
                            {setting.mobile}
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="contact-info-item">
                      <div className="contact-info-icon">
                        <EmailIcon />
                      </div>
                      <div className="contact-info-content">
                        <h4>Email</h4>
                        <p>
                          <Link to={`mailto:${setting.email}`}>
                            {setting.email}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="contact-map">
                    <h2>Map Location</h2>
                    {setting.map_location ? (
                      <iframe
                        src={setting.map_location}
                        frameborder="0"
                      ></iframe>
                    ) : (
                      ""
                    )}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.210151282042!2d85.3143030748199!3d27.67989897619825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19ca44d694ad%3A0x6184656fbe1c67fb!2sGuheswori%20Merchant%20Banking%20%26%20Finance%20Ltd%20.!5e0!3m2!1sne!2snp!4v1707307389194!5m2!1sne!2snp"></iframe>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
