import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import LOGO from "../../assets/img/logo.png";
import Loading from "../../common/loading";
import Errors from "../../common/error";
import Breadcrumbs from "../../common/breadcumb";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "./news.scss";
function NewsDetail() {
  const { category, slug } = useParams();
  let catUrl = category;
  if (category == "news-&-events") {
    catUrl = "news";
  }
  console.log(slug);
  const [detail, setDetail] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}${catUrl}/detail/`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${APIURL}${slug}`);
        setDetail(response.data.detail.en);
        console.log("Details", response.data.detail.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [catUrl, slug]);
  if (loading) return <Loading />;
  if (error) return <Errors />;

  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="faq-box">
          <div className="wrapper">
            <div className="detail-parent">
              <div className="detail-img">
                <img
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  src={detail.doc ? detail.doc : LOGO}
                  alt={detail.title}
                />
              </div>
              <div className="detail-img">
                <img
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  src={detail.image ? detail.image : ""}
                  alt={detail.title}
                />
              </div>
              <div className="description">
                <div
                  dangerouslySetInnerHTML={{
                    __html: detail.description ? detail.description : "",
                  }}
                ></div>
              </div>
            </div>
            <div
              className="modal NewsDetails fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <img
                      src={detail.doc ? detail.doc : LOGO}
                      alt={detail.title}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal NewsDetails fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <img
                      src={detail.image ? detail.image : LOGO}
                      alt={detail.title}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsDetail;
