import React from 'react'
import { Link } from 'react-router-dom'

function ListMenu({child, url}) {
  return (
    <>
        <Link
        to={`${url}${child.slug}`}
        className="dropdown-item"
        >
        {child.title}
        </Link>
        
    </>
  )
}

export default ListMenu
