import React, { useEffect, useState } from "react";
import { LangContext } from "../../context/LangContext";
import NepaliDate from "nepali-date";
// import { format } from "date-fns";
// import { formatInTimeZone } from "date-fns-tz";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
const TopCalendar = () => {
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  //   const d1 = new NepaliDate().format("MMMM D, YYYY");
  //   const dNepali = new NepaliDate().format("mmmm d, yyyy");
  const DaysEnglish = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const DaysNepali = [
    "आइतबार",
    "सोमबार",
    "मङ्गलबार",
    "बुधवार",
    "बिहीबार",
    "शुक्रबार",
    "शनिबार",
  ];
  const DateEnglish = new Date();
  const DateNepali = new NepaliDate().format("mmmm d, yyyy");
  const MainDate = new Date();
  const dayName = DaysEnglish[MainDate.getDay()];
  const englishFormat = `${MainDate.toLocaleString("default", {
    month: "long",
  })} ${MainDate.getDate()} , ${MainDate.getFullYear()}`;
  const dayNameNepali = DaysNepali[MainDate.getDay()];
  const EnglishDate = `${englishFormat} ${dayName}`;
  const NePaliDate = `${DateNepali} ${dayNameNepali}`;

  const d2 = new Date();
  const time = d2.toLocaleTimeString();
  // function tConvert(time) {
  //   time = time
  //     .toString()
  //     .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  //   if (time.length > 1) {
  //     time = time.slice(1);
  //     time[5] = +time[0] < 12 ? " AM" : " PM";
  //     time[0] = +time[0] % 12 || 12;
  //   }
  //   return time.join("");
  // }

  // console.log("dayNameNepali", tConvert(time));
  return (
    <>
      <AccessTimeIcon fontSize="small" />
      <span>{time}</span>
      <CalendarMonthIcon fontSize="small" />
      <span>{nep ? NePaliDate : EnglishDate}</span>
    </>
  );
};
export default TopCalendar;
