import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import "./form.scss";
import { useParams } from "react-router-dom";
import { TabTitle } from "../../common/dynamicTitle";
const GrievanceForm = () => {
  const { slug } = useParams();
  TabTitle(`GMBF | ${slug}`);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    formState,
  } = useForm();
  const { isDirty, isSubmitting, isSubmitSuccessful } = formState;
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verificationLoad, setVerificationLoad] = useState(false);
  const [vCode, setVCode] = useState();
  const navigate = useNavigate();
  function checkProgressHandleClick() {
    navigate("/grievance-response");
  }
  function navigateToHome() {
    navigate("../grievance/grievance-rest-form");
  }
  // For File
  // const [convert, setConvert] = useState();
  // const convert2base64 = (file) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //         setConvert(reader.result.toString());
  //         console.log("reader =>", reader.result.toString());
  //     };
  //     reader.readAsDataURL(file);
  // };
  // for file
  // const handleFileChange = (e) => {
  //     const file = e.target.files[0];
  //     console.log("file =>", file);
  //      (file);
  // };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      if (verificationLoad === false) {
        const { data } = await axios.post(
          `${process.env.REACT_APP_URL}grievance/otp`,
          {
            ...values,
          }
        );
        if (data?.error) {
          toast.error(data.error);
          console.log(data.error);
          setLoading(false);
        } else {
          toast.success(
            "Please Check Your Email and type your verification code here."
          );
          setVCode(data.otp_code);
          setLoading(false);
        }
      } else {
        if (Number(values.verification) === vCode) {
          const { data } = await axios.post(
            `${process.env.REACT_APP_URL}grievance/form`,
            {
              ...values,
            }
          );
          if (data?.error) {
            console.log(data.error);
            toast.error(data.error);
            setLoading(false);
          } else {
            toast.success("Message Successfully Sent.");
            navigateToHome();
            setLoading(false);
          }
        } else {
          toast.error("The verification code does not match. Please try again");
          setLoading(false);
        }
      }
    } catch (err) {
      toast.error("Failed to send. Please try again.");
      console.log(err);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setVerificationLoad(true);
    }
  }, [formState]);
  return (
    <>
      <div className="grievanceFormMainContainer">
        <div className="grievancePageFormContainer">
          <h3>
            {verificationLoad
              ? "Please find the code on the email you provided"
              : "How may We help you?"}
          </h3>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            {!verificationLoad && (
              <>
                <div className="form-group">
                  <label>Full Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    {...register("fullname", {
                      required: "This field is required.",
                    })}
                    className="form-control"
                  />
                  <span class="error">{errors.fullname?.message}</span>
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="form-control"
                    name="email"
                    {...register("email", {
                      required: "This field is required.",
                      pattern: {
                        value:
                          /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  <span class="error">{errors.email?.message}</span>
                </div>
                <div className="form-group">
                  <label>Mobile Number</label>
                  <input
                    type="number"
                    placeholder="Mobile Number"
                    name="mobno"
                    className="form-control"
                    {...register("mobno", {
                      required: "This field is required.",
                      pattern: {
                        value:
                          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                        message: "Invalid Phone or Mobile Number",
                      },
                    })}
                  />
                  <span class="error">{errors.mobno?.message}</span>
                </div>

                <div className="form-group">
                  <label>Subject</label>
                  <input
                    type="text"
                    placeholder="Subject"
                    name="subject"
                    {...register("subject", {
                      required: "This field is required.",
                      pattern: {
                        value: /^.{0,200}$/,
                        message: "Subject must be less than 150 letters",
                      },
                    })}
                    className="form-control"
                  />
                  <span class="error">{errors.subject?.message}</span>
                </div>
                {/* <div className="form-group">
                                    <label>Attachment file</label>
                                    
                                    <input
                                        onChange={(e) => handleFileChange(e)}
                                        className="form-control"
                                        type="file"
                                        name="DocPath"
                                        className="form-control"
                                    />
                                    <span>{errors.attachment?.message}</span>
                                </div> */}
                <div className="form-group">
                  <label>Your Message</label>
                  <textarea
                    rows="5"
                    placeholder="Your Message"
                    name="issue"
                    className="form-control"
                    {...register("issue", {
                      required: "This field is required.",
                      pattern: {
                        value: /^.{10,5000}$/,
                        message: "Message must be between 10 to 5000 letters",
                      },
                    })}
                  />
                  <span class="error">{errors.issue?.message}</span>
                </div>
              </>
            )}
            {verificationLoad && (
              <div className="form-group">
                <label>Email Verification Code</label>
                <input
                  type="text"
                  placeholder="Verification Code"
                  name="verification"
                  className="form-control"
                  {...register("verification", {
                    required: "Verification Code is Required",
                  })}
                />
                <span>{errors.verification?.message}</span>
              </div>
            )}
            <div className="grievanceFormButtonsContainer">
              <button
                className="btn btn-primary btn-sm"
                type="submit"
                disabled={isSubmitting}
              >
                {loading && <ClipLoader size={20} color="#fff"></ClipLoader>}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default GrievanceForm;
