import React from "react";
import "./grid.scss";
import { Link } from "react-router-dom";
function Grid({ grid, url, pdf }) {
  return (
    <div className="items-body">
      {typeof url == "undefined" ? (
        typeof pdf == "undefined" ? (
          <p>{grid.title}</p>
        ) : (
          <p>
            <Link to={`${pdf}`} target="_blank">
              {grid.title}
            </Link>
          </p>
        )
      ) : (
        <p>
          <Link to={`${url}${grid.slug}`}>{grid.title}</Link>
        </p>
      )}
    </div>
  );
}

export default Grid;
