import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./otherInterestRates.scss";
// import.meta.env.VITE_API_URL;
const OtherInterestRate = () => {
  const { slug } = useParams("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = React.useState(false);
  let APIURL;
  switch (slug) {
    case "base-rate":
      APIURL = `${process.env.REACT_APP_URL}invest_interest/all/Base`;
      break;
    case "interest-spread-rate":
      APIURL = `${process.env.REACT_APP_URL}invest_interest/all/Spread`;
      break;
    case "standard-tariff-of-charges":
      APIURL = `${process.env.REACT_APP_URL}invest_interest/all/Commissions`;
      break;
    default:
  }
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setData(response.data.rates.en);
        console.log("BASERATESSSS", response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return "";
  if (error) return "";
  return (
    <>
      {slug == "standard-tariff-of-charges" ? (
        <>
          {data.map((PA) => {
            return (
              <>
                {PA.child.map((CH) => {
                  return (
                    <>
                      <div className="InsidePageContainer">
                        <div className="memberb-box">
                          <div className="wrapper">
                            <div className="member-container">
                              <div
                                className="Chargesss"
                                dangerouslySetInnerHTML={{
                                  __html: CH.description,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            );
          })}
        </>
      ) : (
        <div className="InsidePageContainer">
          <div className="memberb-box">
            <div className="wrapper">
              <div className="member-container">
                {data.length === 0 ? (
                  "No Content Found"
                ) : (
                  <Tabs>
                    <TabList>
                      {data.map((cat) => {
                        return <Tab>{cat.title}</Tab>;
                      })}
                    </TabList>
                    {data.map((cat) => {
                      return (
                        <TabPanel>
                          <section className="OtherInterestRates">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>{"Months"}</th>
                                  <th>
                                    {slug === "base-rate"
                                      ? "Monthly Base Rate %"
                                      : "Spread Rate"}
                                  </th>

                                  {slug === "base-rate" ? (
                                    <th>Average Base Rate %</th>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {cat.child.length === 0
                                  ? "No Content Found"
                                  : cat.child.map((rate) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{rate.title}</td>
                                            <td>{rate.rate}</td>
                                            {slug === "base-rate" ? (
                                              <td>{rate.avg_rates}</td>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                        </>
                                      );
                                    })}
                              </tbody>
                            </table>
                          </section>
                        </TabPanel>
                      );
                    })}
                  </Tabs>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OtherInterestRate;
