import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import Grid from "../../common/grid";
import Breadcrumbs from "../../common/breadcumb";
import Loading from "../../common/loading";
import Errors from "../../common/error";
function ProductsList() {
  const { slug } = useParams(null);
  const [product, setProduct] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}product/all`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setProduct(response.data.items.en);
        console.log(response.data.items.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);

  if (loading) return <Loading />;
  if (error) return <Errors />;

  var appData = {
    extensions: ["doc", "docx", "pdf", "ppt", "rtf", "swf", "txt", "xls"],
  };

  function isSpecialExtension(url, extensionsArray) {
    var extension = getExtensionFromUrl(url);
    if (extension === "") {
      return false;
    } else {
      if (extensionsArray.indexOf(extension) === -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  function getExtensionFromUrl(url) {
    var extension = "";
    var pattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    if (url.match(pattern) && url.match(pattern)[1]) {
      extension = url.match(pattern)[1];
    }
    return extension;
  }
  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="wrapper">
          <div className="InsideFlexBox">
            {product.map((product, index) => {
              const isPdf = product.DocPath === null ? false : true;
              if (isPdf) {
                return (
                  <Grid
                    key={index}
                    grid={product}
                    pdf={
                      product.DocPath === null
                        ? ""
                        : isSpecialExtension(
                            product.DocPath,
                            appData.extensions
                          )
                        ? product.DocPath
                        : ""
                    }
                  />
                );
              } else {
                return <Grid key={index} grid={product} url={"../products/"} />;
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsList;
