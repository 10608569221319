import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import List from "../pages/services";
import DownloadList from "../pages/downloads";
import Faqs from "../pages/faqs";
import NewsAndUpdate from "../pages/newsupdate";
import Careers from "../pages/career";
import Forex from "../pages/forex";
import InterestRate from "../pages/rates/interestRate";
import LatestRate from "../pages/latestRates";
import ContactUs from "../pages/contactUs";
import Search from "../pages/serach/search";
import Breadcrumbs from "./breadcumb";
import Loading from "./loading";
import NoMatch from "./noMatch";
import EMICalculator from "../components/EMICalculator";
function BasePath() {
  const { slug } = useParams(null);
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 3000);
  }, [slug]);
  if (spinner) return <Loading />;
  return (
    <>
      {(() => {
        switch (slug) {
          case "services":
            return <List />;
          case "faqs":
            return <Faqs />;
          case "downloads":
            return <DownloadList />;
          case "interest-rates":
            return <InterestRate />;
          case "forex-rates":
            return <Forex />;
          case "careers":
            return <Careers />;
          case "latest-rates":
            return <LatestRate />;
          case "emi-calculator":
            return <EMICalculator />;
          case "contact-us":
            return <ContactUs />;
          case "search":
            return <Search />;
          case "news-&-events":
            return <NewsAndUpdate />;
          case "csr":
            return <NewsAndUpdate />;
          default:
            return <NoMatch />;
        }
      })()}

      {/* {
        {
          default: <NoMatch />,
          services: <List />,
          faqs: <Faqs />,
          downloads: <DownloadList />,
          "interest-rates": <InterestRate />,
          "forex-rates": <Forex />,
          careers: <Careers />,
          "latest-rates": <LatestRate />,
          "emi-calculator": <EMICalculator />,
          "contact-us": <ContactUs />,
          search: <Search />,
          "news-&-events": <NewsAndUpdate />,
          csr: <NewsAndUpdate />,
        }[slug]
      } */}
    </>
  );
}

export default BasePath;
