import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import img1 from "../../assets/img/epay/img1.png";
import img2 from "../../assets/img/epay/img2.png";
import axios from "axios";
const EpaymentPartner = () => {
  var Epayment = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const [payment, setPayment] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}epayment`;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setPayment(response.data.epayment);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (loading) return '';
  if (error) return '';

  return (
    <>
      <div className="EpayPartnerContainer">
        <div className="EPartner">
          <h4>e-Payment Partner</h4>
          <Slider {...Epayment}>
            {
              payment.map((pay) => {
                return (<div key={pay.id}>
                  <div className="items">
                    <img src={pay.image} alt={pay.title} />
                  </div>
                </div>);
              })
            }


          </Slider>
        </div>
      </div>
    </>
  );
};
export default EpaymentPartner;
