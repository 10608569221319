import { Carousel } from "react-responsive-carousel";
import "./index.scss";
const BannerAddImage = ({ advertisement }) => {
  console.log(advertisement)
  return (
    <div className="adCarousel">
      <Carousel
      autoPlay={true}
      infiniteLoop={true}
      swipeable={true}
      showStatus={false}
      showIndicators={true}
      showThumbs={false}
      interval={3000}
      showArrows={false}
    >
      {advertisement?.map((advertisement, index) => {
        return (
          <div className="">
            <img src={advertisement.featured_img} alt={advertisement.title} />
          </div>
        );
      })}
    </Carousel>
    </div>
  );
};
export default BannerAddImage;
