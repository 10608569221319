import axios from "axios";
import React from "react";
import { useParams } from "react-router";
import "./faqs.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "../../common/accordion";
import Loading from "../../common/loading";
import Errors from "../../common/error";
import { TabTitle } from "../../common/dynamicTitle";
import Breadcrumbs from "../../common/breadcumb";
function Faqs() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [faqs, setFaqs] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}faq`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setFaqs(response.data.items.en);

        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return <Loading />;
  if (error) return <Errors />;

  return (
    <>
      <Breadcrumbs />

      <div className="InsidePageContainer">
        <div className="faq-box">
          <div className="wrapper">
            <div className="faq-container">
              {faqs.length === 0 ? (
                "No Content Found"
              ) : (
                <Tabs>
                  <TabList>
                    {faqs.map((cat) => {
                      return <Tab>{cat.title}</Tab>;
                    })}
                  </TabList>
                  {faqs.map((cat) => {
                    return (
                      <TabPanel>
                        <div className="accordion" id="accordionExample">
                          {cat.child.length === 0
                            ? "No Content Found"
                            : cat.child.map((faq, index) => {
                                return (
                                  <Accordion
                                    key={index}
                                    accordion={faq}
                                    index={index}
                                    type="faqs"
                                  />
                                );
                              })}
                        </div>
                      </TabPanel>
                    );
                  })}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faqs;
