import React from "react";
import SelectBox from "./SelectBox";
import axios from "axios";

function SelectDistrict() {
  const [district, setDistrict] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}district/all`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setDistrict(response.data.district.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (loading) return "";
  if (error) return "";
  return <SelectBox items={district} />;
}

export default SelectDistrict;
