import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import LOGO from "../../assets/img/logo.png";
import "./rateDetail.scss";
import Breadcrumbs from "../../common/breadcumb";
function RateDetail() {
  const { category, slug } = useParams();
  let url = `fiscal_year_wise_interest/detail/`;
  if (category == "interest-rates") {
    url = `interest_rates/detail/`;
  }
  const APIURL = `${process.env.REACT_APP_URL}${url}`;
  const [detail, setDetail] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${APIURL}${slug}`);
        setDetail(response.data.detail);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return "";
  if (error) return "";
  console.log(detail);
  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="faq-box">
          <div className="wrapper">
            <div className="detail-parent">
              <div className="description">
                <div
                  dangerouslySetInnerHTML={{
                    __html: detail.Description ? detail.Description : "",
                  }}
                ></div>
              </div>
              {/* <div className="detail-img">
                <img
                  src={detail.DocPath ? detail.DocPath : LOGO}
                  alt={detail.Title}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RateDetail;
