import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InterestRate from "./interestRate";
import InterestRateFiscalWise from "./interestRateFiscalWise";
import OtherInterestRate from "./otherInterestRate";
import Breadcrumbs from "../../common/breadcumb";
import Loading from "../../common/loading";
import { TabTitle } from "../../common/dynamicTitle";
function Rates() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 3000);
  }, [true]);
  if (spinner) return <Loading />;
  return (
    <>
      <Breadcrumbs />
      {
        {
          "interest-rates": <InterestRate />,
          "fiscal-year-wise-interest": <InterestRateFiscalWise />,
          "base-rate": <OtherInterestRate />,
          "interest-spread-rate": <OtherInterestRate />,
          "standard-tariff-of-charges": <OtherInterestRate />,
        }[slug]
      }
    </>
  );
}

export default Rates;
