import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./index.scss";

import BannerAddImage from "./index2";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
const Banner = ({ banners, advertisement }) => {
  const regex = /(<([^>]+)>)/gi;
  return (
    <>
      <div className="MainBanner">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          swipeable={true}
          showStatus={false}
          showIndicators={true}
          showThumbs={false}
          interval={3000}
          showArrows={true}
          
        >
          {banners.map((banner, index) => {
            return (
              <>
                {banner.file_type == "digital_slider" ? (
                  <div key={index}>
                    <img src={banner.file} alt={banner.Title} />
                    <div className="Captionsss">
                      <h4>Welcome to GMBF</h4>
                      <h1>{banner.Title}</h1>
                      <p>
                        {banner.Description.substring(0, 50).replace(
                          regex,
                          " "
                        )}
                      </p>
                      {/* <a className="MoreBTN active">
                  learn more <ArrowRightAltIcon fontSize="small" />
                </a> */}
                      <Link to="/services" className="MoreBTN active">
                        Explore Services <ArrowRightAltIcon fontSize="small" />
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div key={index}>
                    <img src={banner.file} alt={banner.Title} />
                  </div>
                )}
              </>
            );
          })}
        </Carousel>
        <BannerAddImage advertisement={advertisement} />
      </div>
    </>
  );
};
export default Banner;
