import React from "react";
import "./news.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import Cards from "../../common/card";
import Loading from "../../common/loading";
import Errors from "../../common/error";
import { TabTitle } from "../../common/dynamicTitle";
import Breadcrumbs from "../../common/breadcumb";
function NewsAndUpdate() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);

  let urlSlug = slug;
  if (slug == "news-&-events") {
    urlSlug = "news";
  }
  const [newsAndEvent, setNewsEvent] = React.useState();
  const [isPage, setActive] = React.useState(0);
  const APIURL = `${process.env.REACT_APP_URL}${urlSlug}/all/`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${APIURL}${isPage}`);
        setNewsEvent(response.data);
        console.log("List", response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [urlSlug, isPage]);
  if (loading) return "...";
  if (error) return <Errors />;

  const handlePageChange = (pageNumber) => {
    // console.log(`active page is ${pageNumber}`);
    setActive(pageNumber);
  };

  return (
    <>
      <Breadcrumbs />

      <div className="InsidePageContainer">
        <div className="faq-box">
          <div className="wrapper">
            <ul className="cards">
              {newsAndEvent.items.en.length === 0
                ? "No Content Found"
                : newsAndEvent.items.en.map((news) => {
                    return (
                      <>
                        <Cards key={news.id} card={news} url={`../${slug}/`} />
                      </>
                    );
                  })}
            </ul>
            <div className="parent-pagination">
              <Pagination
                activePage={isPage}
                itemsCountPerPage={newsAndEvent.per_page}
                totalItemsCount={newsAndEvent.total}
                pageRangeDisplayed={6}
                firstPageText="First Page"
                lastPageText="Last Page"
                itemClass="page-item"
                linkClass="page-link"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsAndUpdate;
