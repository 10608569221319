import React from "react";
import "./services.scss";
import axios from "axios";
import Category from "../../common/category";
import { useParams } from "react-router-dom";
import Grid from "../../common/grid";
import Breadcrumbs from "../../common/breadcumb";
import { TabTitle } from "../../common/dynamicTitle";
function List() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [service, setService] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}service/all`;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setService(response.data.items.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return "";
  if (error) return "";

  return (
    <>
      <Breadcrumbs />
      <div className="service-parent">
        <div className="wrapper">
          <div className="Service-container">
            <section>
              {service.map((cat) => {
                return <Grid key={cat.id} grid={cat} url={`../services/`} />;
              })}
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;
