import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./index.scss";
const Introduction = ({ about }) => {
  const { slug } = useParams();
  useEffect(() => {
    const AddClassInh3 = document.querySelectorAll("h4");
    console.log("AddClassInh3", AddClassInh3);
    AddClassInh3[0].setAttribute("class", "mission");
    AddClassInh3[1].setAttribute("class", "vission");
  }, [slug]);

  return (
    <>
      <div className="MainIntro">
        <div className="wrapper">
          <div className="MainIntroCon">
            <div className="IntroText">
              <h4>About Us</h4>
              <h1>{about.title}</h1>
              <div
                className="DengerusHTML"
                dangerouslySetInnerHTML={{ __html: about.description }}
              ></div>
            </div>
            <div className="IntroImages">
              <img src={about.featured_img} alt={about.title} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Introduction;
