import React, { useEffect, useState } from "react";
import TopBar from "./topbar";
import NavBar from "./navbar";
import MobileNav from "./mobilenav";
import Logo from "../../assets/img/logo.jpg";
import { Link } from "react-router-dom";
import TopCalendar from "./topcalendar";
const Header = () => {
  const isSticky = (e) => {
    const header = document.querySelector(".NavBar");
    const scrollTop = window.scrollY;
    if (header) {
      scrollTop >= 5
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  return (
    <>
      <div className="MainHeader">
        <div className="TopBar">
          <div className="wrapper">
            <div className="TopBarBox">
              <div className="TopLeft">
                <TopCalendar />
              </div>
              <div className="TopRight">
                <TopBar />
              </div>
            </div>
          </div>
        </div>
        <div className="NavBar">
          <div className="wrapper">
            <div className="NavBarBox">
              <div className="Logo">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
              <div className="NavItems">
                <NavBar />
              </div>
              <MobileNav />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
