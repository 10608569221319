import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import CompanyProfile from "./companyprofile";
import Team from "./team";
import Officers from "../officers";
import Loading from "../../common/loading";
import { TabTitle } from "../../common/dynamicTitle";
function AboutRoutes() {
  TabTitle(`GMBF | Home`);
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const contents = [
    "introduction",
    "capital-structure",
    "promoters-list",
    "ceo-s-message",
  ];
  const teams = [
    "board-of-directors",
    "management-team",
    "province-head",
    "regional-head",
    "head-of-department",
    "hod",
    "bod",
  ];
  const officers = [
    "company-secretary",
    "grievance-handling-officer",
    "compliance-officer",
    "information-officer",
  ];
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 3000);
  }, []);
  if (spinner) return <Loading />;
  switch (true) {
    case contents.includes(slug):
      return <CompanyProfile />;
    case teams.includes(slug):
      return (
        <div>
          <Team />
        </div>
      );
    case officers.includes(slug):
      return <Officers />;
    default:
      return <div>Exception</div>;
  }
}

export default AboutRoutes;
