import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import img1 from "../../assets/img/Products/img1.jpg";
import img2 from "../../assets/img/Products/img2.jpg";
import img3 from "../../assets/img/Products/img3.jpg";
import logo from "../../assets/img/logo.png";
import { Link } from "react-router-dom";
const MainProducts = ({ product }) => {
  var Products = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="MainServiceContainer">
        <div className="wrapper">
          <div className="ServicesBox">
            <div className="ServicesTitle">
              <h4>products</h4>
              <h1>best products to our valued clients</h1>
              <p>GMBF provides best products to our valued clients</p>
              <Link to="/products" className="MoreBTN">
                more products <ArrowRightAltIcon fontSize="small" />{" "}
              </Link>
            </div>
            <div className="ServicesItemsBox">
              <Slider {...Products}>
                {product.map((pro) => {
                  return (
                    <div>
                      <div className="items">
                        <div className="img">
                          <Link to={`products/${pro.slug}`}>
                            <img
                              src={pro.image ? pro.image : logo}
                              alt={pro.title}
                            />
                          </Link>
                        </div>
                        <h1>
                          <Link to={`products/${pro.slug}`}>{pro.title}</Link>
                        </h1>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainProducts;
