import React from "react";
import GOLD from "../../assets/img/rates/gold.png";
import NEPSE from "../../assets/img/rates/nepse.png";
import FOREX from "../../assets/img/rates/forex.png";
import "./latestRates.scss";
import { Link } from "react-router-dom";
import { TabTitle } from "../../common/dynamicTitle";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../common/breadcumb";
function LatestRate() {
  const { slug } = useParams();
  TabTitle(`GMBF | ${slug}`);
  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="main-box">
          <div className="wrapper">
            <div className="main-box-container">
              <Link
                to="https://www.nepalstock.com.np/company/detail/263"
                target="_blank"
              >
                <div className="latest-rate-body">
                  <div className="latest-rate-img">
                    <img src={NEPSE} alt="NEPSE Prices" />
                  </div>
                  <div className="latest-rate-title">NEPSE Rates</div>
                </div>
              </Link>
              <Link to="https://www.fenegosida.org/" target="_blank">
                <div className="latest-rate-body">
                  <div className="latest-rate-img">
                    <img src={GOLD} alt="Gold Rates" />
                  </div>
                  <div className="latest-rate-title">Gold Rates</div>
                </div>
              </Link>
              <Link to="../forex-rates">
                <div className="latest-rate-body">
                  <div className="latest-rate-img">
                    <img src={FOREX} alt="Fores Rates" />
                  </div>
                  <div className="latest-rate-title">Fores Rates</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LatestRate;
