import React, { useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./index.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import SingleMenu from "../../common/singleMenu";
import Advertisement from "../../common/advertisement";
import { LangContext } from "../../context/LangContext";
const NavBar = () => {
  const lang = React.useContext(LangContext);
  const nep = lang.state.np;
  const [menu, setMenu] = React.useState();
  const MianData = useState([]);
  const APIURL = `${process.env.REACT_APP_URL}menu/main`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setMenu(response.data.menu);
        console.log("Menu", response.data.menu);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (nep) {
    MianData.current = menu?.np;
  } else {
    MianData.current = menu?.en;
  }
  if (loading) return "...";
  if (error) return "";

  const notShowingTitle = ["products", "investor-relation"];

  return (
    <>
      <ul className="NavMenuItems">
        {MianData.current.map((parent, index) => {
          if (typeof parent?.child === "undefined") {
            return (
              <li key={parent.id} className="nav-item">
                <Link to={parent.slug} className="nav-link">
                  {parent.title}
                </Link>
              </li>
            );
          } else {
            return (
              <li key={parent.id} className="nav-item">
                <Link
                  key={index}
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {parent.title}
                  <KeyboardArrowDownIcon fontSize="medium" />
                </Link>
                <div className="dropdown-menu">
                  <div className="DropDownBox">
                    {!notShowingTitle.includes(parent.slug) ? (
                      <h1>{parent.title}</h1>
                    ) : (
                      ""
                    )}
                    <div className="MenuFlex">
                      <ul
                        className={
                          parent.slug == "our-network" ? "Network" : ""
                        }
                      >
                        {parent.child.map((childs, index) => {
                          switch (true) {
                            case notShowingTitle.includes(parent.slug):
                              return (
                                <li key={childs.id}>
                                  <div className="ProductsItemsMenu">
                                    {childs.products.length === 0 ? (
                                      parent.slug === "investor-relation" ? (
                                        <>
                                          {index === 0 ? <h3>Reports</h3> : ""}

                                          <Link
                                            to={`${parent.slug}/${childs.slug}`}
                                            className="dropdown-item"
                                          >
                                            {childs.title}
                                          </Link>
                                        </>
                                      ) : (
                                        <Link
                                          to={`${parent.slug}/${childs.slug}`}
                                          className="dropdown-item"
                                        >
                                          {childs.title}S
                                        </Link>
                                      )
                                    ) : (
                                      <>
                                        <h3>{childs.title}</h3>
                                        <ul>
                                          {childs.products.map((product) => {
                                            switch (parent.slug) {
                                              case "products":
                                                return (
                                                  <SingleMenu
                                                    key={product.id}
                                                    slug={""}
                                                    child={product}
                                                    url="products/"
                                                  />
                                                );
                                              case "investor-relation":
                                                return (
                                                  <SingleMenu
                                                    key={product.id}
                                                    slug={""}
                                                    child={product}
                                                    url="investor-relation/"
                                                  />
                                                );
                                              default:
                                                return (
                                                  <SingleMenu
                                                    key={product.id}
                                                    slug={""}
                                                    child={product}
                                                    url=""
                                                  />
                                                );
                                            }
                                          })}
                                        </ul>
                                      </>
                                    )}
                                  </div>
                                </li>
                              );
                            default:
                              return (
                                <SingleMenu
                                  key={childs.id}
                                  slug={parent.slug}
                                  child={childs}
                                />
                              );
                          }
                        })}
                      </ul>
                      {!notShowingTitle.includes(parent.slug) ? (
                        <Advertisement slug={parent.slug} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </li>
            );
          }
        })}
      </ul>
    </>
  );
};
export default NavBar;
