import React from "react";
import Card from "./Card";

const SearchList = ({ filteredSearch }) => {
  const filtered = filteredSearch?.map((searchItem) => (
    <Card key={searchItem.id} searchItem={searchItem} />
  ));
  return <div className="SearchFilterData">{filtered}</div>;
};

export default SearchList;
