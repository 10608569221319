import React from "react";
import "../services/services.scss";
import "../services/services.scss";
import axios from "axios";
import Category from "../../common/category";
import Breadcrumbs from "../../common/breadcumb";
import { useParams } from "react-router-dom";
import Loading from "../../common/loading";
import Errors from "../../common/error";
import { TabTitle } from "../../common/dynamicTitle";
function Product() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [product, setProduct] = React.useState();
  const [category, setCategory] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}product/detail/${slug}`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setProduct(response.data.detail.en);
        setCategory(response.data.category.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="wrapper">
          <div className="InsideFlexBox">
            <div className="services-body">
              <div
                className="CKContentBOX"
                dangerouslySetInnerHTML={{
                  __html: product.description
                    ? product.description
                    : "No Content Found",
                }}
              ></div>
            </div>
            <div className="service-aside">
              <h3>Related Products</h3>
              <ul>
                {category.map((cat) => {
                  return (
                    <Category key={cat.id} cat={cat} url={`../products/`} />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
