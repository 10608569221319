import React from 'react'
import grievancess from "../../assets/img/gmbf grievance img.png";
import './index.scss'
import GrievanceForm from './form';
import { useParams } from 'react-router-dom';
import GrievanceResForm from './verifyForm';
import Breadcrumbs from '../../common/breadcumb';
function Grievance() {
  const {slug} = useParams();
  return (
    <>
        <Breadcrumbs />
        <div className="InSidepageSection">
            <div className="wrapper">
                <div className="grivance">
                    <div className="grivance-image">
                        <img src={grievancess} alt="Saptakoshi Development Bank Limited Grievance" />
                    </div>
                    <div className="grivance-form">
                        {
                            {
                                'gmbf-grievance': <GrievanceForm />,
                                'grievance-rest-form': <GrievanceResForm />
                            }[slug]
                        } 
                    </div>
                </div>
            </div>
        </div>
    </>
    
  )
}

export default Grievance
