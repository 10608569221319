import React, { useState } from "react";
// import Scroll from "./Scroll";
import SearchList from "./searchList";
import "./search.css";
import axios from "axios";
import Images from "../../assets/img/search.svg";
// import.meta.env.VITE_API_URL;
import INLoader from "../../common/insideloading";
// import INLoader from "../../Common/insideloading";
// import Errors from "../Common/error";
// import { TabTitle } from "../Common/dynamicTitle";
function Search() {
  //   TabTitle(`GMBF | Search`);
  // const APIURL = `${import.meta.env.VITE_API_URL}search/search`;
  const APIURL = `${process.env.REACT_APP_URL}search/search`;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);
  const [Data, setData] = useState([]);
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.post(
          APIURL,
          { search_word: searchField },
          { headers: { "content-type": "Content-type:application/json" } }
        );
        setLoading(true);
        setData(response?.data?.items);
        console.log("response?.data?.items", response?.data?.items);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [searchField]);

  const handleChange = (e) => {
    setSearchField(e.target.value);
    if (e.target.value === "") {
      setSearchShow(false);
    } else {
      setSearchShow(true);
    }
  };

  function searchList() {
    if (loading) return <INLoader />;
    if (error) return "Eee";
    if (searchShow) {
      return (
        // <Scroll>
        //   <SearchList filteredSearch={Data} />
        // </Scroll>
        <div className="serachItembox">
          <SearchList filteredSearch={Data} />
        </div>
      );
    }
  }
  return (
    <div className="InSidepageSection">
      <div className="wrapper">
        <div className="searchBox">
          <div className="form-group">
            <input
              className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
              type="search"
              placeholder="Search...."
              value={searchField}
              onChange={handleChange}
            />
            <button>Search</button>
          </div>
          {searchShow || Data.length > 0 ? (
            searchList()
          ) : (
            <div className="searchimage">
              <img src={Images} alt="Images" />
              <p>Please type the keywords to continue the search</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Search;
