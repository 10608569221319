import React from "react";
import "./nodata.scss";
import { Link } from "react-router-dom";
import Logo from "../assets/img/fav.png";
const NoMatch = () => {
  return (
    <>
      <div className="InSidepageSection">
        <div className="wrapper">
          <div className="errors">
            <div>
              <img src={Logo} />
              <h1>
                <span className="fade-in" id="digit1">
                  4
                </span>
                <span className="fade-in" id="digit2">
                  0
                </span>
                <span className="fade-in" id="digit3">
                  4
                </span>
              </h1>
              <h3 className="fadeIn">PAGE NOT FOUND</h3>
              <Link to="/" name="button">
                Return To Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoMatch;
