import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import OfficerCard from "../../common/officerCard";
import "./index.scss";
import Breadcrumbs from "../../common/breadcumb";
import Loading from "../../common/loading";
import Errors from "../../common/error";
import { TabTitle } from "../../common/dynamicTitle";
function Officers() {
  let { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [officers, setOfficers] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}Officer/holder/`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  switch (slug) {
    case "grievance-handling-officer":
      slug = "Grievance";
      break;
    case "compliance-officer":
      slug = "Compliance";
      break;
    case "information-officer":
      slug = "Information";
      break;
    default:
  }

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${APIURL}${slug}`);
        setOfficers(response.data.officer);

        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="main-box">
          <div className="wrapper">
            <div className="main-box-container">
              {officers.map((officer) => {
                return (
                  <div className="officer-body">
                    <OfficerCard key={officer.id} officer={officer} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Officers;
