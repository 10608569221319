import React from "react";
import "./team.scss";
import TeamCard from "../../components/card/teamCard";
import axios from "axios";
import { useParams } from "react-router";
import Breadcrumbs from "../../common/breadcumb";
import Loading from "../../common/loading";
import Errors from "../../common/error";
function Team() {
  let { slug } = useParams(null);
  const [content, setContent] = React.useState();
  if (slug === "board-of-directors") {
    slug = "bod";
  }
  if (slug === "head-of-department") {
    slug = "hod";
  }
  if (slug === "province-head") {
    slug = "regional-head";
  }
  const APIURL = `${process.env.REACT_APP_URL}team/all/${slug}`;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setContent(response.data.items.en);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return <Loading />;
  if (error) return <Errors />;

  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="wrapper">
          <div className="team InsideFlexBox">
            {content.length === 0
              ? "No Team Found."
              : content.map((team) => {
                  console.log("______T", team);
                  return (
                    <div
                      className={
                        team?.designation_name == "Chairman" ||
                        team.designation_name == "Chief Executive Officer"
                          ? "Chairman"
                          : team.team_group_id == "3"
                          ? "HOD"
                          : team.team_group_id == "2"
                          ? "MNG"
                          : team.team_group_id == "4"
                          ? "PRH"
                          : team.designation_name == "Director (Promoter)"
                          ? "Promoter"
                          : "Director"
                      }
                      key={team.id}
                    >
                      <TeamCard team={team} />
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;
