import "./index.scss";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import img1 from "../../assets/img/serviceicons/img1.png";
import { Link } from "react-router-dom";
const MainServices = ({ digital }) => {
  return (
    <>
      <div className="MainServicesContainer">
        <div className="wrapper">
          <div className="MainTitle">
            <div>
              <h4>Services</h4>
              <h1>Comprehensive Financial Services</h1>
              <p>Empowering Your Financial Journey with Tailored Services</p>
            </div>
          </div>
          <div className="MainServicesBoxCon">
            {digital.map((digit) => {
              return (
                <div className="ServicesItems">
                  <div className="items">
                    <Link to={`services/${digit.slug}`}>
                      <img src={digit.image} alt={digit.title} />
                    </Link>
                    <h4>
                      <Link to={`services/${digit.slug}`}>{digit.title}</Link>
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ViewMoreBtn">
            <Link to="/services">
              Explore More <ArrowRightAltIcon fontSize="small" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainServices;
