import React from 'react'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';
function Tablebody({tbody}) {
    var appData = {
        extensions: ['doc', 'docx', 'pdf', 'ppt', 'rtf', 'swf', 'txt', 'xls']
    };

    var imageData = {
        extensions: ['png', 'jpg', 'jpeg', 'jpg', 'gif', 'tiff', 'psd']
    };

    function isSpecialExtension(url, extensionsArray) {
        var extension = getExtensionFromUrl(url);
        if (extension === '') {
            return false;
        } else {
            if (extensionsArray.indexOf(extension) === -1) {
                return false;
            } else {
                return true;
            }
        }
    }

    function getExtensionFromUrl(url) {
        var extension = '';
        var pattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        if (url.match(pattern) && url.match(pattern)[1]) {
            extension = url.match(pattern)[1];
        }
        return extension;
    }
    return (
        <>
        {
            tbody.map((tbody) => {
                return(
                        
                        <td>{isSpecialExtension(tbody, appData.extensions) ?
                            <Link to={tbody} target='_blank'><PictureAsPdfIcon/></Link> : 
                            isSpecialExtension(tbody, imageData.extensions) ?
                            <Link to={tbody} target='_blank'><ImageIcon/></Link>
                            :
                            <span>{tbody}</span>}</td> 

                );
            })
        }
    
        
        </>
    );
}

export default Tablebody
