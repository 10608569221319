import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
const Setting = ({ setting }) => {

    return (
        <>
            <div className="FooterItem">
                <h3>
                    {setting.site_name.substring(0, 19)} <br />
                    {setting.site_name.substring(19)}
                </h3>
                <p>
                    <LocationOnIcon fontSize="small" />
                    <span>{setting.address}</span>
                </p>
                <p>
                    <LocalPhoneIcon fontSize="small" />
                    <span>{setting.telephone}</span>
                </p>
                <p>
                    <MailIcon fontSize="small" />
                    <span>{setting.email}</span>
                </p>
                <h4>OFFICE HOURS</h4>
                <p>{setting.opening_time} - {setting.closing_time} (SUN-THU)</p>
                <p>{setting.opening_time_friday} - {setting.closing_time_friday} (FRI)</p>
            </div>

        </>
    );
};
export default Setting;
