import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import img1 from "../../assets/img/supporticons/img1.png";
import img2 from "../../assets/img/supporticons/img2.png";
import img3 from "../../assets/img/supporticons/img3.png";
import img4 from "../../assets/img/supporticons/img4.png";
import OfficerCard from "../../common/officerCard";
import { Link } from "react-router-dom";
const MainSupport = ({ support }) => {
  return (
    <>
      <div className="MainSupportContainer">
        <div className="wrapper">
          <div className="SUpportFlexContainer">
            <div className="SupportBox">
              <h4>Support</h4>
              <div className="BoxContainer">
                <div className="Box">
                  <Link to="rates/interest-rates">
                    <img src={img1} alt="Image" />
                  </Link>

                  <h3>
                    <Link to="/rates/interest-rates">rates</Link>
                  </h3>
                </div>
                <div className="Box">
                  <Link to="/contact-us">
                    <img src={img2} alt="Image" />
                  </Link>
                  <h3>
                    <Link to="/contact-us">locate us</Link>
                  </h3>
                </div>
                <div className="Box">
                  <Link to={`../emi-calculator`}>
                    <img src={img3} alt="Image" />
                  </Link>
                  <h3><Link to={`../emi-calculator`}>EMI Calculator</Link></h3>
                </div>
                <div className="Box">
                <Link to={`../csr`}>
                  <img src={img4} alt="Image" />
                </Link>
                  <h3><Link to={`../csr`}>Commitment to CSR</Link></h3>
                </div> 
              </div>
            </div>
            <div className="GrievenceOfficerBox">
              <div className="accordion" id="accordionExample">
                {support?.map((support, index) => {
                  return (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`#collapse${index}`}
                        >
                          {support.parent}
                        </button>
                      </h2>
                      <div
                        key={index}
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          index === 0 ? "show" : ""
                        }`}
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <OfficerCard key={index} officer={support.child} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainSupport;
