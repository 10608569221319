import React from 'react'
import { Link } from 'react-router-dom';
import ListMenu from './listMenu';

function SingleMenu({slug, child, url}) {
  
    switch (slug) {
        case "about-us":
          return (
            <li key={child.id}>
                <ListMenu key={child.id} child={child} url="about/" />
            </li>
          );
        case "services":
          return (
            <li key={child.id}>
                <ListMenu key={child.id} child={child} url="services/" />
            </li>
          );
        
        case "our-network":
          return (
            <li key={child.id}>
                <ListMenu key={child.id} child={child} url="network/" />
            </li>
          );
        default:
            if(url){
                return (
                    <li key={child.id}>
                        <ListMenu key={child.id} child={child} url={url} />
                    </li>
                  );
            }else{
                return (
                    <li key={child.id}>
                        <ListMenu key={child.id} child={child} url="" />
                    </li>
                  );
            }
          
      }
}

export default SingleMenu
