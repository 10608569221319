import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Grid from "../../common/grid";
import "./fiscal.scss";
function InterestRateFiscalWise() {
  const { slug } = useParams(null);
  const [interest, setInterst] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}fiscal_year_wise_interest/`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${APIURL}`);
        setInterst(response.data.items.en);
        console.log(APIURL, response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return "";
  if (error) return "";
  var appData = {
    extensions: ["doc", "docx", "pdf", "ppt", "rtf", "swf", "txt", "xls"],
  };

  function isSpecialExtension(url, extensionsArray) {
    var extension = getExtensionFromUrl(url);
    if (extension === "") {
      return false;
    } else {
      if (extensionsArray.indexOf(extension) === -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  function getExtensionFromUrl(url) {
    var extension = "";
    var pattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    if (url.match(pattern) && url.match(pattern)[1]) {
      extension = url.match(pattern)[1];
    }

    return extension;
  }
  return (
    <>
      <div className="InsidePageContainer">
        <div className="memberb-box">
          <div className="wrapper">
            <div className="member-container">
              {interest.length === 0 ? (
                "No Content Found"
              ) : (
                <Tabs>
                  <TabList>
                    {interest.map((cat) => {
                      return <Tab>{cat.title}</Tab>;
                    })}
                  </TabList>
                  {interest.map((cat) => {
                    return (
                      <TabPanel>
                        <section>
                          {cat.interest_rates.length === 0
                            ? "No Content Found"
                            : cat.interest_rates.map((interests) => {
                                return (
                                  <>
                                    {isSpecialExtension(
                                      interests.doc,
                                      appData.extensions
                                    ) ? (
                                      <Grid
                                        key={interests.id}
                                        grid={interests}
                                        pdf={interests.doc}
                                      />
                                    ) : (
                                      <Grid
                                        key={interests.id}
                                        grid={interests}
                                        url={""}
                                      />
                                    )}
                                  </>
                                );
                              })}
                        </section>
                      </TabPanel>
                    );
                  })}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InterestRateFiscalWise;
