import React, { useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./index.css";
// import LazyLoad from "react-lazy-load";
const PopUps = ({ Popupss }) => {
  const [visible, setVisible] = React.useState(false);
  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!pop_status) {
      setVisible(true);
      localStorage.setItem("pop_status", 1);
    }
  }, []);
  window.onbeforeunload = function () {
    localStorage.removeItem("pop_status");
  };

  if (!visible) return null;
  return (
    <>
      {Popupss.length == 0 ? (
        ""
      ) : (
        <div className="MainPopups">
          <div
            className="modal fade show"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    className="skip_btns"
                    onClick={() => {
                      document.querySelector(".show")?.classList.remove("show");
                    }}
                  >
                    Skip <i className="zmdi zmdi-arrow-right"></i>
                  </button>
                  <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    swipeable={true}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                    interval={3000}
                  >
                    {Popupss?.map((Pop, index) => {
                      return (
                        <div key={index}>
                          <img src={Pop.file ? Pop.file : ""} alt="Images" />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PopUps;
