import React, { createContext, useReducer } from "react";

export const LangContext = createContext();

const initialState = {
  np: false,
  npRender: "data.en",
};

const LangReducer = (state, action) => {
  switch (action.type) {
    case "ENGLISH":
      return { np: false, npRender: "data.en" };
    case "NEPALI":
      return { np: true, npRender: "data.np" };
    default:
      return state;
  }
};

export function LangProvider(props) {
  const [state, dispatch] = useReducer(LangReducer, initialState);

  return (
    <LangContext.Provider value={{ state, dispatch }}>
      {props.children}
    </LangContext.Provider>
  );
}
