import Banner from "../banner";
import Introduction from "../introduction";
import MainServices from "../services";
import MainProducts from "../products";
import MainNews from "../newsandnotice";
import MainSupport from "../support";
import React, { useState } from "react";
import axios from "axios";
import Loading from "../../common/loading";
import Errors from "../../common/error";
import { TabTitle } from "../../common/dynamicTitle";
import PopUps from "../popups";
const HomePage = () => {
  TabTitle(`GMBF | Home`);
  const [data, setData] = useState([]);
  const APIURL = `${process.env.REACT_APP_URL}home`;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setData(response.data);
        console.log("HOME_______", response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (loading) return <Loading />;
  if (error) return <Errors />;
  return (
    <>
      <PopUps Popupss={data.popups} />
      <Banner banners={data.banners} advertisement={data?.advertisement?.en} />
      <Introduction about={data?.about?.en} />
      <MainServices digital={data?.services?.en} />
      <MainProducts product={data?.products?.en} />
      <MainNews
        news={data.news.en}
        app_store={data?.site_setting?.app_store}
        google_play={data?.site_setting?.google_play}
      />
      <MainSupport support={data?.support} />
    </>
  );
};
export default HomePage;
