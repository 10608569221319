import React from "react";
import TableHead from "../../common/thead";
import axios from "axios";
import { useParams } from "react-router-dom";
import TableBody from "../../common/tbody";
import "./index.scss";
import Breadcrumbs from "../../common/breadcumb";
import NoDataPage from "../../common/nodata";
import Loading from "../../common/loading";
import Errors from "../../common/error";
import { TabTitle } from "../../common/dynamicTitle";
function InveestorRelation() {
  const { slug } = useParams();
  TabTitle(`GMBF | ${slug}`);
  const [investor, setInvestor] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}reports/all/`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${APIURL}${slug}`);
        setInvestor(response.data.items.en);
        console.log("investor-relation", response.data.items.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return <Loading />;
  if (error) return <Errors />;

  const thead = ["Title", "File"];
  return (
    <>
      {investor.length == "0" ? (
        <NoDataPage />
      ) : (
        <>
          <Breadcrumbs />
          <div className="InsidePageContainer">
            <div className="wrapper">
              <table className="InvestorTable">
                <thead>
                  <tr>
                    <TableHead thead={thead} />
                  </tr>
                </thead>
                <tbody>
                  {investor.map((investor, index) => {
                    delete investor.created;
                    delete investor.category_id;
                    return (
                      <tr key={index}>
                        <TableBody tbody={Object.values(investor)} />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default InveestorRelation;
