import React from "react";
import { useNavigate } from "react-router-dom";
const Card = ({ searchItem }) => {
  const navigate = useNavigate();
  const searchTerm = searchItem.module_name.split("_").join(" ");
  let urls = "";
  switch (searchItem.module_name) {
    case "csr_type":
      urls = `../csr`;
      break;
    case "tbl_csr":
      urls = `../csr`;
      break;

    case "digital_services":
      urls = `../digital_services/${searchItem.slug}`;
      break;
    case "download_category":
      urls = `../downloads`;
      break;
    case "tbl_download":
      urls = `../download`;
      break;
    case "blogs":
      urls = `../blogs/${searchItem.slug}`;
      break;

    case "faq_category":
      urls = `../faq`;
      break;
    case "faq":
      urls = `../faq`;
      break;
    case "interest_rate":
      urls = `../rates/${searchItem.slug}`;
      break;
    case "other_interest_rate":
      urls = `../rates/${searchItem.slug}`;
      break;
    case "product_category":
      urls = `../products/${searchItem.slug}`;
      break;
    case "tbl_atm":
      urls = `../branches-&-atm`;
      break;
    case "tbl_branches":
      urls = `../branches-&-atm`;
      break;
    case "tbl_services":
      urls = `../service/${searchItem.slug}`;
      break;
    case "tbl_interest_rate_fiscal":
      urls = `../rates/${searchItem.slug}`;
      break;
    case "videos":
      urls = `../videos`;
      break;
    case "tbl_news":
      urls = `../news/${searchItem.slug}`;
      break;
    case "report_category":
      urls = `../reports/${searchItem.slug}`;
      break;
    case "tbl_report":
      urls = `../reports/${searchItem.slug}`;
      break;
    case "product_category":
      urls = `../products/${searchItem.slug}`;
      break;
    case "tbl_products":
      urls = `../products/${searchItem.slug}`;
      break;
    default:
  }
  return (
    <div className="">
      <div
        onClick={() => {
          navigate(urls);
        }}
      >
        <span>{searchTerm}</span>
        <h2>{searchItem.title}</h2>
        <button>
          Know More <i className="zmdi zmdi-arrow-right-top"></i>
        </button>
      </div>
    </div>
  );
};

export default Card;
