import React from "react";

function SelectBox({items}) {
  return (
    <>
    {items.map(item => {
        return (
            <>
          <option value={item.id}>
            {item.title}
          </option>
          </>
        );
      })}
    </>
      
  );
}

export default SelectBox;
