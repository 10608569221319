import React from "react";
import "./officerCard.scss";
function OfficerCard({ officer }) {
  console.log("GGG____", officer);
  return (
    <>
      <div className="Imgs">
        <img src={officer.featured_image} alt={officer.name} />
      </div>
      <div className="GDetails">
        <h3>
          <strong>Name :</strong> {officer.name}
        </h3>
        {/* <p>
          <strong>Designation : </strong> {officer.name}
        </p> */}
        <p>
          <strong>Contact :</strong>
          {officer.contact}
        </p>
        {officer.type == "Information" ? (
          ""
        ) : officer.type == "Compliance" ? (
          ""
        ) : (
          <p>
            <strong>Mobile No :</strong> {officer.mobile}
          </p>
        )}

        <p>
          <strong>Email : </strong> {officer.email}
        </p>
      </div>
    </>
  );
}

export default OfficerCard;
