import React from "react";
import CareerForm from "./careerForm";

function Accordion({ accordion, index, type }) {
  console.log("career", accordion);
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo">
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapseTwo${accordion.id}`}
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          {accordion.title}
        </button>
      </h2>
      <div
        id={`collapseTwo${accordion.id}`}
        className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body container">
          <div className="JobDescription">
            <p>
              <b>Job Position :</b>
              {accordion.title}
            </p>
            <p>
              <b>No of Openings :</b>
              {accordion.jobNumber}
            </p>
            <p>
              <b>Job Location :</b>
              {accordion.branches.map((b, i) => {
                return <span key={i}>{b.PageTitle}</span>;
              })}
            </p>
            <p>
              <b>Experience :</b>
              {accordion.experience}
            </p>
            <p>
              <b>Expiry date :</b>
              {accordion.EndDate}
            </p>
            <p dangerouslySetInnerHTML={{ __html: accordion.description }}></p>
          </div>

          {type === "career" ? (
            <CareerForm selectedbranch={accordion} id={accordion.id} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
