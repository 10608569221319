import React from "react";
import "./teamCard.scss";

function TeamCard({ team }) {
  return (
    <div className="team-card">
      <div className="card-img-top">
        <img src={team.featured_image} alt={team.name} />
      </div>
      <div className="card-body">
        <div className="name">{team.name}</div>
        <div className="post">
          {team.designation_name || team.department_name}
        </div>
      </div>
    </div>
  );
}

export default TeamCard;
