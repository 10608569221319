import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import "./forex.scss";
import { TabTitle } from "../../common/dynamicTitle";
import Breadcrumbs from "../../common/breadcumb";
function Forex() {
  const { slug } = useParams();
  TabTitle(`GMBF | ${slug}`);
  const [forex, setForex] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}forex`;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setForex(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (loading) return "";
  if (error) return "";

  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="main-box">
          <div className="wrapper">
            <div className="main-box-forex-container">
              {forex.map((forex) => {
                return (
                  <>
                    <div className="forex-title">
                      <h4>
                        Last Updated : <span>{forex.date_forex}</span>
                      </h4>
                    </div>
                    <div className="forex-body">
                      <table>
                        <thead>
                          <tr>
                            <th>Currency</th>
                            <th>Unit</th>
                            <th>Buy</th>
                            <th>Sell</th>
                          </tr>
                        </thead>
                        <tbody>
                          {forex.forex_values.map((child) => {
                            return (
                              <tr key={child.id}>
                                <td>{child.name}</td>
                                <td>
                                  {child.unit} <span>{child.iso3}</span>
                                </td>
                                <td>{child.buy}</td>
                                <td>{child.sell}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forex;
