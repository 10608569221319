import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BranchAndATM from "./branchandatm";
import Members from "./members";
import Loading from "../../common/loading";
import { TabTitle } from "../../common/dynamicTitle";
function OurNetworks() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const contents = ["branches", "atm-location", "extension-counter"];
  const members = ["member-network"];
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 3000);
  }, []);
  if (spinner) return <Loading />;
  switch (true) {
    case contents.includes(slug):
      return <BranchAndATM />;
    case members.includes(slug):
      return <Members />;
    default:
      return <div>Exception</div>;
  }
}

export default OurNetworks;
