import React from "react";
import "./companyprofile.scss";
import axios from "axios";
import { useParams } from "react-router";
import Loading from "../../common/loading";
import Errors from "../../common/error";
const CompanyProfile = () => {
  const { slug } = useParams(null);
  const [content, setContent] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}content/detail/${slug}`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setContent(response.data.detail.en);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return <Loading />;
  if (error) return <Errors />;

  return (
    <>
      <div className="InsidePageContainer">
        <div className="InsidePageTitle">
          <div className="wrapper">
            <h1>{slug.replace("-", " ")}</h1>
          </div>
        </div>
        <div className="IntroContainer">
          <div className="wrapper">
            {!content ? (
              "No Content Found."
            ) : (
              <div className="IntroFlexbox">
                <div className="IntroContent">
                  {/* <h1>{content?.Title}</h1> */}
                  <div
                    className="CKContentBox"
                    dangerouslySetInnerHTML={{
                      __html: content?.Description ? content?.Description : "",
                    }}
                  ></div>
                </div>
                {content.CoverImage ? (
                  <div className="IntroImage">
                    <img src={content?.CoverImage} alt={content?.Title} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyProfile;
