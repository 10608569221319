import React from 'react'
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link, useParams } from 'react-router-dom';
function Category({ cat, url }) {
    const { slug } = useParams(null);
    return (
        <>
            <li className={cat.slug === slug ? 'active' : ''}>
                <ArrowRightAltIcon fontSize='small' />
                <Link to={url + cat.slug} >{cat.title}</Link>
            </li>
        </>
    )
}

export default Category