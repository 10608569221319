import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";
import OfflineS from "../common/offline";
import Header from "../components/header/index";
import HomePage from "../components/home";
import Footer from "../components/footer";
import AboutRoutes from "../pages/about";
import Product from "../pages/products";
import OurNetworks from "../pages/network";
import Detail from "../pages/services/detail";
import BasePath from "../common";
import NewsDetail from "../pages/newsupdate/NewsDetail";
import Rates from "../pages/rates";
import RateDetail from "../pages/rates/rateDetail";
import Grievance from "../pages/grievance";
import InveestorRelation from "../pages/investor";
import ProductsList from "../pages/products/productsList";
import { LangProvider } from "../context/LangContext";
import NoMatch from "../common/noMatch";
const MyRoutes = () => {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 3000);
  }
  return (
    !loading && (
      <>
        <LangProvider>
          <Header />
          <Online>
            <Routes>
              <Route path="*" element={<NoMatch />} />
              <Route path="/" element={<HomePage />} />
              <Route path=":slug" element={<BasePath />} />
              <Route path="about/:slug" element={<AboutRoutes />} />
              <Route path="services/:slug" element={<Detail />} />
              <Route path="products" element={<ProductsList />} />
              <Route path="products/:slug" element={<Product />} />
              <Route path="network/:slug" element={<OurNetworks />} />
              <Route path=":category/:slug" element={<NewsDetail />} />
              <Route path="rates/:slug" element={<Rates />} />
              <Route
                path="rates/:category/:slug"
                exact
                element={<RateDetail />}
              />
              <Route path="grievance/:slug" element={<Grievance />} />
              <Route
                path="investor-relation/:slug"
                exact
                element={<InveestorRelation />}
              />
            </Routes>
          </Online>
          <Offline>
            <OfflineS />
          </Offline>
          <Footer />
        </LangProvider>
      </>
    )
  );
};
export default MyRoutes;
