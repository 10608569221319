import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./index.scss";
import googleplay from "../../assets/img/google-play.png";
import AppStore from "../../assets/img/app-store.png";
const MainNews = ({ google_play, news, app_store }) => {
  console.log("News", news);
  console.log("app_store", app_store);
  return (
    <>
      <div className="MainNewsContainer">
        <div className="wrapper">
          <div className="NewsFlexBox">
            <div className="NewsBox">
              <h4>News & Notices</h4>
              <h1>
                Your <span>news</span> & information
              </h1>
              <ul>
                {news.map((ne) => {
                  return (
                    <li>
                      <span>
                        {ne.day} <br /> {ne.month}
                      </span>
                      <Link
                        to={
                          ne.image == "" ? ne.file : `news-&-events/${ne.slug}`
                        }
                        target={ne.image == "" ? "_blank" : ""}
                      >
                        {ne.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <Link to="/news-&-events" className="MoreBTN">
                see more
                <ArrowRightAltIcon fontSize="small" />
              </Link>
            </div>
            <div className="AppDownloadBox">
              <div className="DownloadApp">
                <h1>
                  Get Instant <span>Digital Payment</span> For Everyone
                </h1>
                <p>
                  Download the Guheswori Smart App and simplify your banking.
                  Discover quick, simple and convenient way to take command of
                  your account.
                </p>
                <p>Get Guheswori Smart App Now. Download Now.</p>

                <Link to={google_play} target="_blank">
                  <img src={googleplay} alt="Image" />
                </Link>
                <Link to={app_store} target="_blank">
                  <img src={AppStore} alt="Image" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainNews;
