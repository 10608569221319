import React from "react";
import Accordion from "../../common/accordion";
import { useParams } from "react-router-dom";
import axios from "axios";
import { TabTitle } from "../../common/dynamicTitle";
import Breadcrumbs from "../../common/breadcumb";
function Careers() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [careers, setCareers] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}career/all`;
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setCareers(response.data.items.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return "";
  if (error) return "Error";
  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="faq-box">
          <div className="wrapper">
            <div className="faq-container">
              <div className="accordion" id="accordionExample">
                {careers.length === 0
                  ? "No Content Found"
                  : careers.map((career, index) => {
                      return (
                        <Accordion
                          key={index}
                          accordion={career}
                          index={index}
                          type="career"
                        />
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Careers;
