import React, { useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "./careerForm.scss";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import file from "../assets/img/file.png";
import SelectBranch from "./SelectBranch";
import ProvienceSelect from "./ProvienceSelect";
import SelectDistrict from "./SelectDistrict";

function CareerForm({ id, selectedbranch }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    formState,
  } = useForm({ shouldUseNativeValidation: true });
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [convert, setConvert] = React.useState();
  const [convertCert, setcertConvert] = React.useState();
  const APIURLP = `${process.env.REACT_APP_URL}province`;
  const APIURLD = `${process.env.REACT_APP_URL}district/all`;
  const [Provience, setProvience] = React.useState();
  const [SProvience, setSProvience] = React.useState();
  const [TProvience, setTProvience] = React.useState();
  const [SDistict, setSDistict] = React.useState();
  const [STDistict, setSTDistict] = React.useState();
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURLP);
        setProvience(response.data.provience.en);
        // console.log("API", response.data.provience.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);

  const ChangeProvience = (e) => {
    setSProvience(e.target.value);
    axios
      .get(
        `https://admin.gmbf.com.np/api/dependency/provience_wise_district/${e.target.value}`
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          let $html = `<option key=${0} value="">Select District</option>`;
          response?.data?.district?.map((district, index) => {
            $html += `<option key=${index + 1} value=${district.id}>
                ${district.title}
              </option>`;
          });
          document.getElementById("permanent_district").innerHTML = $html;
        }
      });
  };

  const ChangeDistict = (e) => {
    setSDistict(e.target.value);
    axios
      .get(
        `https://admin.gmbf.com.np/api/dependency/district_wise_municipality/${e.target.value}`
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          let $html = `<option key=${0} value="0">Select municipality</option>`;
          response?.data?.municipality?.map((municipality, index) => {
            $html += `<option key=${index + 1} value=${municipality.id}>
                ${municipality.title}
              </option>`;
          });
          document.getElementById("permanent_municipality").innerHTML = $html;
        }
      });
  };

  // temporary

  const ChangeProvienceT = (e) => {
    setTProvience(e.target.value);
    // console.log("Temp", e.target.value);
    axios
      .get(
        `https://admin.gmbf.com.np/api/dependency/provience_wise_district/${e.target.value}`
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          let $html = `<option key=${0} value="">Select District</option>`;
          response?.data?.district?.map((district, index) => {
            $html += `<option key=${index + 1} value=${district.id}>
                ${district.title}
              </option>`;
          });
          document.getElementById("temporary_district").innerHTML = $html;
        }
      });
  };

  const ChangeDistictST = (e) => {
    setSTDistict(e.target.value);
    axios
      .get(
        `https://admin.gmbf.com.np/api/dependency/district_wise_municipality/${e.target.value}`
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          let $html = `<option key=${0} value="0">Select municipality</option>`;
          response?.data?.municipality?.map((municipality, index) => {
            $html += `<option key=${index + 1} value=${municipality.id}>
                ${municipality.title}
              </option>`;
          });
          document.getElementById("temporary_municipality").innerHTML = $html;
        }
      });
  };
  const convert2base64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setConvert(reader.result.toString());
    };
    reader.readAsDataURL(file);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    convert2base64(file);
  };
  const convertcert2base64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setcertConvert(reader.result.toString());
    };
    reader.readAsDataURL(file);
  };
  const handleCertificateFileChange = (e) => {
    const file = e.target.files[0];
    convertcert2base64(file);
  };
  const genders = [
    { value: "", label: "Select Gender" },
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];
  const maritals = [
    { value: "", label: "Select Marital Status" },
    { value: "Married", label: "Married" },
    { value: "Single", label: "Single" },
    { value: "Unmarried", label: "UnMarried" },
    { value: "Divorced", label: "Divorced" },
  ];
  const percentage = [
    { value: "", label: "Select Percentage/CGPA" },
    { value: "Percentage", label: "Percentage" },
    { value: "CGPA", label: "CGPA" },
  ];

  const onSubmit = async (formValues) => {
    console.log("FormDatatat", typeof formValues);
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_URL}career/form`,
        {
          ...formValues,
          DocPath: convert,
          certificate: convertCert,
        },
        { headers: { "content-type": "Content-type:application/json" } }
      )
      .then(({ data }) => {
        console.log("submit", data);
        if (data?.status_code === 200) {
          toast.success(data.status_code, {
            position: "top-right",
            message: data.status_message,
          });
          reset();
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
        } else {
          toast.error(data.error, {
            position: "top-right",
            message: data.status_message,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(`error${error}`);
      });
  };
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setSuccess(false);
  //   }, 1000);
  // }, []);

  // for hide and show for percentage and CGPA
  const percentCGPAElement = document.getElementById("percentage_CGPA");
  if (percentCGPAElement) {
    const percentageInput = document.querySelector("#input-per");
    const cgpaInput = document.querySelector("#input-cgpa");
    percentCGPAElement.addEventListener("change", (event) => {
      if (event.target.value) {
        if (event.target.value == "Percentage") {
          percentageInput.style.display = "block";
          cgpaInput.style.display = "none";
        } else {
          percentageInput.style.display = "none";
          cgpaInput.style.display = "block";
        }
      }
    });
  }
  return (
    <>
      <div className="right">
        <div className="header">
          <h2 className="animation a1">Apply Form</h2>
        </div>
        <div className="form">
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <h4 className="animation a2">Personal Details</h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    id="first_name"
                    className="form-field animation a3"
                    placeholder="First Name"
                    {...register("first_name", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid First name",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.first_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="middle_name">Middle Name</label>
                  <input
                    type="text"
                    id="middle_name"
                    className="form-field animation a3"
                    placeholder="Middle Name"
                    {...register("middle_name", {
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Middle name",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.middle_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    className="form-field animation a3"
                    placeholder="Last Name"
                    {...register("last_name", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Last name",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.last_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="bod">Date of Birth</label>
                  <input
                    type="date"
                    id="bod"
                    className="form-field animation a3"
                    placeholder="DOB (AD) "
                    {...register("bod", {
                      required: "This field is required.",
                      valueAsDate: true,
                    })}
                  />
                  <span className="text-danger">{errors.bod?.message}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="father_name">Father's Name</label>
                  <input
                    type="text"
                    id="father_name"
                    className="form-field animation a3"
                    placeholder="Father's Name"
                    {...register("father_name", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid  name",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.father_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="mother_name">Mother's Name</label>
                  <input
                    type="text"
                    id="mother_name"
                    className="form-field animation a3"
                    placeholder="Mother's Name"
                    {...register("mother_name", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Last name",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.mother_name?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    className="form-field animation a4"
                    placeholder="Email Address"
                    {...register("email", {
                      required: "Please enter your email.",
                      pattern: {
                        value:
                          /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
                        message: "Enter Valid Email address",
                      },
                    })}
                  />
                  <span className="text-danger">{errors.email?.message}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="mobno">Phone Number</label>
                  <input
                    type="number"
                    id="mobno"
                    className="form-field animation a4"
                    placeholder="Phone Number"
                    {...register("mobno", {
                      required: "Mobile Number is Required",
                      // valueAsNumber: true,
                      minLength: {
                        value: 7,
                        message: "Phone number min value 10 digit",
                      },
                      maxLength: {
                        value: 10,
                        message: "Phone number max value 13 digit",
                      },
                      pattern: {
                        value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                        message: "Enter Valid  Phone or Mobile Number",
                      },
                    })}
                  />
                  <span className="text-danger">{errors.mobno?.message}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="gender">Gender</label>
                  <select
                    className="form-field animation a4"
                    id="gender"
                    {...register("gender", {
                      required: "This field is required.",
                    })}
                  >
                    {genders.map((gender) => {
                      return (
                        <option value={gender.value}>{gender.label}</option>
                      );
                    })}
                  </select>

                  <span className="text-danger">{errors.gender?.message}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="nationality">Nationality</label>
                  <input
                    type="text"
                    id="nationality"
                    className="form-field animation a4"
                    placeholder="Nationality"
                    {...register("nationality", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid name",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.nationality?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="marital_status">Marital Status</label>
                  <select
                    className="form-field animation a4"
                    id="marital_status"
                    {...register("marital_status", {
                      required: "This field is required.",
                    })}
                  >
                    {maritals.map((marital) => {
                      return (
                        <option value={marital.value}>{marital.label}</option>
                      );
                    })}
                  </select>

                  <span className="text-danger">
                    {errors.marital_status?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="citizenship_no"> Citizenship Number</label>
                  <input
                    type="text"
                    id="citizenship_no"
                    className="form-field animation a5"
                    placeholder="xxxxxx-xxxxx"
                    {...register("citizenship_no", {
                      required: "This field is required",
                      // maxLength: 20,
                      // pattern: {
                      //   value: /^[0-9a-zA-Z]+$/,
                      //   message: "Enter Valid Citizenship No",
                      // },
                    })}
                  />
                  <span className="text-danger">
                    {errors.citizenship_no?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="citizen_issue_location">
                    Citizenship Issue Location
                  </label>
                  <input
                    type="text"
                    id="citizen_issue_location"
                    className="form-field animation a5"
                    placeholder="Citizenship Issue Location"
                    {...register("citizen_issue_location", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid name",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.citizen_issue_location?.message}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="animation a5">Permanent Address</h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="permanent_provience">
                    Permanent Provience
                  </label>
                  <select
                    className="form-field animation a6"
                    {...register("permanent_provience", {
                      required: "This field is required.",
                    })}
                    id="permanent_provience"
                    value={SProvience}
                    onChange={ChangeProvience}
                  >
                    <option value="">Select Provience</option>
                    <ProvienceSelect />
                  </select>
                  <span className="text-danger">
                    {errors.permanent_provience?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="permanent_district">Permanent District</label>
                  <select
                    className="form-field animation a6"
                    {...register("permanent_district", {
                      required: "This field is required.",
                    })}
                    id="permanent_district"
                    value={SDistict}
                    onChange={ChangeDistict}
                  >
                    <option value="">Select Distict</option>
                    <SelectDistrict />
                  </select>
                  <span className="text-danger">
                    {errors.permanent_district?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="permanent_municipality">
                    Permanent Municipality
                  </label>
                  <select
                    className="form-field animation a6"
                    {...register("permanent_municipality", {
                      required: "This field is required.",
                    })}
                    // name="permanent_municipality"
                    id="permanent_municipality"
                  >
                    <option value="">Select municipality Name</option>
                  </select>
                  <span className="text-danger">
                    {errors.permanent_municipality?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="permanent_ward">Permanent Ward Number</label>
                  <input
                    type="number"
                    className="form-field animation a6"
                    placeholder="Ward"
                    id="permanent_ward"
                    {...register("permanent_ward", {
                      required: "This field is required",

                      minLength: {
                        value: 2,
                        message: "Ward number min 2 digit",
                      },
                      maxLength: {
                        value: 3,
                        message: "Ward number max 3 digit",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.permanent_ward?.message}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="animation a7">Temporary Address</h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="temporary_provience">
                    Pemporary Provience
                  </label>
                  <select
                    className="form-field animation a6"
                    id="temporary_provience"
                    {...register("temporary_provience", {
                      required: "This field is required.",
                    })}
                    name="temporary_provience"
                    value={TProvience}
                    onChange={ChangeProvienceT}
                  >
                    <option value="">Select Provience Name</option>
                    {Provience?.map((p, key) => {
                      return (
                        <option key={key} value={p.id}>
                          {p.title}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.temporary_provience?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="temporary_district">Temporary District</label>
                  <select
                    className="form-field animation a6"
                    {...register("temporary_district", {
                      required: "This field is required.",
                    })}
                    // name="temporary_district"
                    id="temporary_district"
                    value={STDistict}
                    onChange={ChangeDistictST}
                  >
                    <option value="">Select District Name</option>
                    <SelectDistrict />
                  </select>
                  <span className="text-danger">
                    {errors.temporary_district?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="temporary_municipality">
                    Temporary Municipality
                  </label>
                  <select
                    className="form-field animation a6"
                    {...register("temporary_municipality", {
                      required: "This field is required.",
                    })}
                    name="temporary_municipality"
                    id="temporary_municipality"
                  >
                    <option value="">Select municipality Name</option>
                  </select>
                  <span className="text-danger">
                    {errors.temporary_municipality?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="temporary_ward">Temporary Ward Number</label>
                  <input
                    type="number"
                    className="form-field animation a8"
                    id="temporary_ward"
                    placeholder="Ward No:"
                    {...register("temporary_ward", {
                      required: "This field is required.",
                      minLength: {
                        value: 2,
                        message: "Ward number min 3 digit",
                      },
                      maxLength: {
                        value: 3,
                        message: "Ward number max 3 digit",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.temporary_ward?.message}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="animation a9">Academic Detail</h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="highest_education">
                    Higest Education Qualification
                  </label>
                  <input
                    type="text"
                    id="highest_education"
                    className="form-field animation a10"
                    placeholder="Higest Education Qualification"
                    {...register("highest_education", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Value",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.highest_education?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="per_cgpa">Percentage CGPA</label>
                  <select
                    className="form-field animation a10"
                    id="percentage_CGPA"
                    {...register("per_cgpa", {
                      required: "This field is required.",
                    })}
                  >
                    {percentage.map((percentage) => {
                      return (
                        <option value={percentage.value}>
                          {percentage.label}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.per_cgpa?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 input-per">
                <div className="form-group" id="input-per">
                  <label htmlFor="percent">Percentage</label>
                  <input
                    type="number"
                    id="percent"
                    className="form-field animation a1"
                    placeholder="Enter number"
                    {...register("percent", {
                      // required: "This field is required.",
                      valueAsNumber: true,
                      max: {
                        value: 100,
                        message: "Percentage max value is 100%",
                      },
                    })}
                  />
                  <span className="text-danger">{errors.percent?.message}</span>
                </div>
                <div className="form-group" id="input-cgpa">
                  <label htmlFor="cgpa">CGPA</label>
                  <input
                    type="text"
                    id="cgpa"
                    className="form-field animation a1"
                    placeholder="Enter CGPA"
                    {...register("cgpa", {
                      maxLength: {
                        value: 4,
                        message: "Enter Valid CGPA",
                      },
                    })}
                  />
                  <span className="text-danger">{errors.cgpa?.message}</span>
                </div>
              </div>
            </div>
            <h4 className="animation a11">Professional Detail</h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="experiences_in_yr">
                    Experiences In Years
                  </label>
                  <input
                    type="date"
                    id="experiences_in_yr"
                    className="form-field animation a2"
                    placeholder="Experiences In Years"
                    {...register("experiences_in_yr", {
                      required: "This field is required.",
                    })}
                  />
                  <span className="text-danger">
                    {errors.experiences_in_yr?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="current_position">Current Position</label>
                  <input
                    type="text"
                    id="current_position"
                    className="form-field animation a12"
                    placeholder="Current Position"
                    {...register("current_position", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Value",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.current_position?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="curent_industry">CurrentIndusrty</label>
                  <input
                    type="text"
                    id="curent_industry"
                    className="form-field animation a12"
                    placeholder="CurrentIndusrty"
                    {...register("curent_industry", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Value",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.curent_industry?.message}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="animation a13">
              References (Please list two references)
            </h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="reference_name_one">Reference Name</label>
                  <input
                    type="text"
                    id="reference_name_one"
                    className="form-field animation a14"
                    placeholder="Reference"
                    {...register("reference_name_one", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Value",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.reference_name_one?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="reference_name_two">
                    Another Reference Name
                  </label>
                  <input
                    type="text"
                    id="reference_name_two"
                    className="form-field animation a14"
                    placeholder="Reference"
                    {...register("reference_name_two", {
                      required: "This field is required.",
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Please Enter a valid Value",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.reference_name_two?.message}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="expected_salary">Expected Salary</label>
                  <input
                    type="number"
                    className="form-field animation a14"
                    placeholder="Expected Salary"
                    {...register("expected_salary", {
                      required: "This field is required.",
                      maxLength: {
                        value: 10,
                        message: "Expected Salary Max 10 Digit",
                      },
                    })}
                  />
                  <span className="text-danger">
                    {errors.expected_salary?.message}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="animation a15">Apply For</h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="branch_name">Branch Name</label>
                  <select
                    className="form-field animation a16"
                    {...register("branch_name", {
                      required: "This field is required.",
                    })}
                  >
                    <option value="">Select Branch Name</option>
                    {/* <SelectBranch /> */}
                    {selectedbranch.branches.map((branches, index) => {
                      return (
                        <option value={branches.id} key={index}>
                          {branches.PageTitle}
                        </option>
                      );
                    })}
                  </select>
                  <span className="text-danger">
                    {errors.branch_name?.message}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="animation a17">Upload Documents</h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group filesss">
                  <label htmlFor="filess">
                    <img src={file} alt="image" />
                    Upload CV
                  </label>
                  <input
                    type="file"
                    {...register("DocPath", {
                      required: "This field is required.",
                    })}
                    name="DocPath"
                    id="filess"
                    className="form-field form-controls animation a18"
                    placeholder="DocPath"
                    onChange={(e) => handleFileChange(e)}
                  />
                  <span className="text-danger">{errors.DocPath?.message}</span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="form-group filesss">
                  <label htmlFor="certificate">
                    <img src={file} alt="image" />
                    Citizenship
                  </label>
                  <input
                    type="file"
                    {...register("certificate", {
                      required: "This field is required.",
                    })}
                    name="certificate"
                    id="certificate"
                    className="form-field form-controls animation a19"
                    placeholder="Certificate"
                    onChange={(e) => handleCertificateFileChange(e)}
                  />
                  <span className="text-danger">
                    {errors.certificate?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <input {...register("career_id")} type="hidden" value={id} />
            </div>
            {success ? (
              <>
                <div className="success-message">
                  Successfully apply for this Job
                </div>
              </>
            ) : (
              ""
            )}
            {loading ? (
              <>
                <div className="spinner"></div>
              </>
            ) : (
              <button className="animation a20">Apply Now</button>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default CareerForm;
