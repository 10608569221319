import React from 'react'
import Logo from "../assets/img/logo.png";
import axios from 'axios';

function Advertisement({slug}) {
    const [advertisement, setAdvertisement] = React.useState();
    const APIURL = `${process.env.REACT_APP_URL}advertisement/detail/`;

    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        (async () => {
        try {
            setLoading(true);
            setError(false);
            const response = await axios.get(`${APIURL}${slug}`);
            setAdvertisement(response.data.detail.en);

            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
        })();
    }, [slug]);
    if (loading) return "";
    if (error) return "";
    return (
        <div className="DefaultImages">
            <div>
            <img src={advertisement ? advertisement.featured_img: Logo} alt={advertisement ? advertisement.title: 'Logo'} />
            </div>
        </div>
    )
}

export default Advertisement
