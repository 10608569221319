import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/fav.png";
import "./nodata.scss";
const Errors = () => {
  return (
    <>
      <div className="InSidepageSection">
        <div className="wrapper">
          <div className="InsideFlex">
            <div>
              <img src={Logo} />
              <h3>Server did not response</h3>
              <p>Contact to Administrator</p>
              {/* <p>
                <a href="/">Realod Page</a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Errors;
