import useBreadcrumbs from "use-react-router-breadcrumbs";
import React from "react";
import { useParams } from "react-router-dom";
import "./common.css";
const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();
  const { slug } = useParams();
  return (
    <div className="breadcrumsss">
      <div className="wrapper">
        <div className="Breds">
          {breadcrumbs.map(({ breadcrumb }) => breadcrumb)}
        </div>
        <div className="PageTitle">
          {slug === undefined ? "" : slug.split("-").join(" ")}
        </div>
      </div>
    </div>
  );
};
export default Breadcrumbs;
