import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../network/member.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import Grid from "../../common/grid";
import Breadcrumbs from "../../common/breadcumb";
import { TabTitle } from "../../common/dynamicTitle";
function DownloadList() {
  const { slug } = useParams(null);
  TabTitle(`GMBF | ${slug}`);
  const [member, setMember] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}downloads`;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setMember(response.data.downloads_category.en);
        console.log("-----------", response.data.downloads_category.en);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return "";
  if (error) return "";
  var appData = {
    extensions: ["doc", "docx", "pdf", "ppt", "rtf", "swf", "txt", "xls"],
  };

  function isSpecialExtension(url, extensionsArray) {
    var extension = getExtensionFromUrl(url);
    if (extension === "") {
      return false;
    } else {
      if (extensionsArray.indexOf(extension) === -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  function getExtensionFromUrl(url) {
    var extension = "";
    var pattern = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    if (url.match(pattern) && url.match(pattern)[1]) {
      extension = url.match(pattern)[1];
    }
    return extension;
  }

  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="memberb-box">
          <div className="wrapper">
            <div className="member-container">
              {member.length === 0 ? (
                "No Content Found"
              ) : (
                <Tabs>
                  <TabList>
                    {member.map((cat) => {
                      return <Tab>{cat.cat_title}</Tab>;
                    })}
                  </TabList>
                  {member.map((cat) => {
                    return (
                      <TabPanel>
                        <section>
                          {cat.child.length === 0
                            ? "No Content Found"
                            : cat.child.map((child) => {
                                return (
                                  <Grid
                                    key={child.id}
                                    grid={child}
                                    pdf={
                                      isSpecialExtension(
                                        child.doc,
                                        appData.extensions
                                      )
                                        ? child.doc
                                        : ""
                                    }
                                  />
                                );
                              })}
                        </section>
                      </TabPanel>
                    );
                  })}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DownloadList;
