import React from 'react'

function TableHead({thead}) {
  return (
    <>
    {
        thead.map((thead, index) => {
            return(
                    <th>{thead}</th>
            );
        })
    }
   
      
    </>
  )
}

export default TableHead
