import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./member.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import Grid from "../../common/grid";
import Breadcrumbs from "../../common/breadcumb";
import Loading from "../../common/loading";
import Errors from "../../common/error";
function Members() {
  const { slug } = useParams(null);
  const [member, setMember] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}member_network`;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setMember(response.data.member_network_category);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return <Loading />;
  if (error) return <Errors />;

  return (
    <>
      <Breadcrumbs />
      <div className="InsidePageContainer">
        <div className="memberb-box">
          <div className="wrapper">
            <div className="member-container">
              {member.length === 0 ? (
                "No Content Found"
              ) : (
                <Tabs>
                  <TabList>
                    {member.map((cat) => {
                      return <Tab>{cat.title}</Tab>;
                    })}
                  </TabList>
                  {member.map((cat) => {
                    return (
                      <TabPanel>
                        <section>
                          {cat.networks.length === 0
                            ? "No Content Found"
                            : cat.networks.map((network) => {
                                return <Grid key={network.id} grid={network} />;
                              })}
                        </section>
                      </TabPanel>
                    );
                  })}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Members;
