import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./mobilenav.scss";
import Logo from "../../assets/img/logo.png";
import TopBar from "./topbar";
import NavBar from "./navbar";
const MobileNav = () => {
  useEffect(() => {
    const OpenCloseBTN = document.getElementById("MenuIcons");
    const CloseBtn = document.getElementById("CloseBTNS");
    const MobileNavSHow = document.querySelectorAll(".MobileNavContainer");
    MobileNavSHow.forEach((eL) => {
      OpenCloseBTN.addEventListener("click", () => {
        console.log("ADD");
        document.body.style.overflow = "hidden";
        eL.classList.add("active");
      });
      CloseBtn.addEventListener("click", (el) => {
        console.log("REMOVE");
        eL.classList.remove("active");
        document.body.style.overflow = "auto";
      });
    });
  });
  return (
    <>
      <div className="HambergerMenuBox" id="MenuIcons">
        <MenuIcon fontSize="large" />
      </div>
      <div className="MobileNavContainer">
        <div className="MobileNavItemBox">
          <div className="CloseBTN" id="CloseBTNS">
            <CloseIcon fontSize="large" />
          </div>
          <div className="MobileViewLogo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="MobileNavBox">
            <NavBar />
            <TopBar />
          </div>
        </div>
      </div>
    </>
  );
};
export default MobileNav;
