import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import axios from "axios";

const GrievanceResForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        formState,
    } = useForm();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(false);
    const [resData, setResData] = useState(null);
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const onSubmit = async (values) => {
        setLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL}grievance/check`, values);
            if (data?.error) {
                toast.error(data.error);
                setLoading(false);
            } else if (data?.status_code === 307) {
                toast.error("Email or Submission Code does not match");
                setLoading(false);
            } else if (data?.status_code === 200) {
                setResData(data);
                toast.success("Submission Code Verified");
                setLoading(false);
            }
        } catch (err) {
            toast.error("Failed to send. Please try again.");
            console.log(err);
            setLoading(false);
        }
    };
    return (
        <>
            {resData?.status_code === 200 ? (
                <div className="grievanceFormResponseAnsMainContainer">
                    <h4 className="grievanceFormResHead">
                        Hello, {resData?.grevience.name}
                    </h4>
                    <p className="grievanceFormResSubHead">
                        The issue you posted on {resData.grevience.created}{" "}
                        {resData.grevience.status === "0" ? (
                            <span>is under process. Please check again later.</span>
                        ) : (
                            <span>is already answered.</span>
                        )}
                    </p>
                    <h5 className="grievanceFormResIssue">Issue :</h5>
                    <p className="grievanceFormResIssueSubject">
                        {resData.grevience.subject}
                    </p>
                    <p className="grievanceFormResIssueText">
                        {isReadMore
                            ? resData.grevience.issue.slice(0, 400)
                            : resData.grevience.issue}
                        {resData.grevience.issue.length > 400 && (
                            <span onClick={toggleReadMore} className="read-or-hide">
                                {isReadMore ? "... read more" : " show less"}
                            </span>
                        )}
                    </p>
                    {resData.grevience.issue_reply !== null && (
                        <h5 className="grievanceFormResIssue">Answer :</h5>
                    )}
                    <p
                        className="grievanceFormResIssueAns"
                        dangerouslySetInnerHTML={{
                            __html: resData.grevience.issue_reply,
                        }}
                    ></p>
                </div>
            ) : (
                <div className="grievanceFormMainContainer">
                    <div className="grievancePageFormContainer">
                        <h3>Check your result.</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input
                                    type="text"
                                    placeholder="Email Address"
                                    name="email"
                                    {...register("email", {
                                        required: "Email is Required",
                                        pattern: {
                                            value:
                                                /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
                                            message: "Invalid email address",
                                        },
                                    })}
                                    className="form-control"
                                />
                                <span>{errors.email?.message}</span>
                            </div>
                            <div className="form-group">
                                <label>Submission Code</label>
                                <input
                                    type="text"
                                    placeholder="Submission Code"
                                    name="user_code"
                                    {...register("user_code", {
                                        required: "Submission Code is Required",
                                    })}
                                    className="form-control"
                                />
                                <span>{errors.user_code?.message}</span>
                            </div>
                            <button
                                className="btn btn-sm btn-primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {loading && <ClipLoader size={20} color="#fff"></ClipLoader>}
                                Check
                            </button>
                        </form >
                    </div >
                </div >
            )}
        </>
    );
};
export default GrievanceResForm;