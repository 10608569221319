import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/fav.png";
import "./nodata.scss";
const OfflineS = () => {
  return (
    <>
      <div className="InSidepageSection">
        <div className="wrapper">
          <div className="InsideFlex">
            <div>
              <img src={Logo} />
              <h3>Connect to the internet</h3>
              <p>You're offline. Check your connection.</p>
              <p>
                <a href="/">Retry</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OfflineS;
