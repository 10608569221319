import React from "react";
import "./index.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EpaymentPartner from "../epaymentpartner";
import Setting from "./setting";
import axios from "axios";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
const Footer = () => {
  const [setting, setSetting] = React.useState();
  const APIURL = `${process.env.REACT_APP_URL}site_settings`;

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setSetting(response.data.site_settings);
        console.log(response.data.site_settings);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (loading) return "..";
  if (error) return "";

  return (
    <>
      <div className="MainFooterContainer">
        <div className="wrapper">
          <div className="FooterItemBox">
            <Setting setting={setting} />
            <div className="FooterItem">
              <h4>QUICK LINKS</h4>
              <ul>
                {/* <li>
                  <Link to={"/"}>Home</Link>
                </li> */}
                {/* <li>
                  <Link to={"services"}>Services</Link>
                </li>
                <li>
                  <Link to={"emi-calculator"}>EMI Calculator</Link>
                </li> */}
                <li>
                  <Link to={"rates/interest-rates"}>Interest Rates</Link>
                </li>
                <li>
                  <Link to={"/rates/standard-tariff-of-charges"}>
                    Standard Tariff of Charges
                  </Link>
                </li>
                <li>
                  <Link to={"rates/fiscal-year-wise-interest"}>
                    Fiscal Year Wise Interest
                  </Link>
                </li>
                <li>
                  <Link to={"rates/base-rate"}>Base Rate</Link>
                </li>
                <li>
                  <Link to={"rates/interest-spread-rate"}>
                    Interest Spread Rate
                  </Link>
                </li>
                <li>
                  <Link to={"forex-rates"}>Forex Rates</Link>
                </li>
                <li>
                  <Link to="https://www.fenegosida.org/" target="_blank">
                    Gold/Silver Prices
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.nepalstock.com.np/company/detail/263"
                    target="_blank"
                  >
                    Stock Exchange
                  </Link>
                </li>
                {/* <li>
                  <Link to={"careers"}>Careers</Link>
                </li> */}
              </ul>
            </div>
            <div className="FooterItem">
              <h4>ABOUT US</h4>
              <ul>
                <li>
                  <Link to={"about/introduction"}>Introduction</Link>
                </li>
                {/* <li>
                  <Link to={"about/coes-message"}>CEOs Message</Link>
                </li> */}
                <li>
                  <Link to={"about/bod"}>Board of Directors</Link>
                </li>
                <li>
                  <Link to={"about/management-team"}>Management Team</Link>
                </li>
                <li>
                  <Link to={"about/head-of-department"}>
                    Head of Department
                  </Link>
                </li>
                <li>
                  <Link to={"about/province-head"}>Province Head</Link>
                </li>
              </ul>
            </div>
            <div className="FooterItem">
              <h4>SUPPORT</h4>
              <ul>
                <li>
                  <Link to={"contact-us"}>Contact Us</Link>
                </li>
                <li>
                  <Link to={"downloads"}>Downloads</Link>
                </li>
                <li>
                  <Link to={`news-&-events`}>News & Events</Link>
                </li>
                <li>
                  <Link to={`network/branches`}>Branches</Link>
                </li>
                <li>
                  <Link to={`faqs`}>FAQs</Link>
                </li>
              </ul>
            </div>
          </div>
          <EpaymentPartner />
        </div>
      </div>
      <div className="CopyRights">
        <div className="wrapper">
          <div className="CopyrightContent">
            <ul>
              <li>
                <Link to={setting.facebook} target="_blank">
                  <FacebookIcon fontSize="small" />
                </Link>
              </li>
              <li>
                <Link to={setting.instagram} target="_blank">
                  <InstagramIcon fontSize="small" />
                </Link>
              </li>
              <li>
                <Link to={setting.linked_in} target="_blank">
                  <LinkedInIcon fontSize="small" />
                </Link>
              </li>
              <li>
                <Link to={setting.twitter} target="_blank">
                  <TwitterIcon fontSize="small" />
                </Link>
              </li>
              <li>
                <Link to={setting.youtube} target="_blank">
                  <YouTubeIcon fontSize="small" />
                </Link>
              </li>
            </ul>
            <p>
              © {new Date().getFullYear()} | {setting.site_name} | All Right
              Reserved.
            </p>
          </div>
        </div>
      </div>
      <ScrollToTop smooth />
    </>
  );
};
export default Footer;
